import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import { useSiteMetadata } from '../hooks/useMetadata';
import { Seo } from '../components/seo';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { TwoDates } from '../components/twodates';

export default function TimePage({ data, location }) {
  const { frontmatter, body, excerpt, slug } = data.content;
  const { title } = frontmatter;
  const { siteUrl } = useSiteMetadata();

  // <p>{data.timeline.nodes.length} nodes in the timeline</p>

  // {data.timeline.nodes.map((node, index) =>
  //   <React.Fragment key={node.frontmatter.title + 'tl' + index}>
  //     <li>{node.slug}</li>
  //   </React.Fragment>
  // )}


  return (
    <Layout
      up={data.up}
    >
      <Seo
        title={title}
        description={excerpt}
        pathname={`${siteUrl}/${slug}`}
        article
      />
      <div className="main-grid ">
        <section>
          <h1 className="mt-0 sticky">{title}</h1>
          <TwoDates data={frontmatter} />
        </section>
        <section className="main_time">
          { frontmatter.featuredImage ? (
          <GatsbyImage image={getImage(frontmatter.featuredImage)} alt={frontmatter.featuredImageAlt} />
          ) : null }
          <MDXRenderer>{body}</MDXRenderer>
        </section>
      </div>

    </Layout>
  );
}

export const query = graphql`
  query TIME_BY_SLUG($slugTimeline: String, $slug: String) {
    up: mdx(slug: { eq: $slug }) {
      id
      frontmatter {
        title
      }
      slug
    }
    content: mdx(slug: { eq: $slugTimeline }) {
      id
      excerpt
      body
      slug
      frontmatter {
        title
        date
        exactDate
        branch
        featuredImageAlt
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
